<template>

<div style="width: 80%; margin: auto" class="mb-5" >
 <v-slide-group
   v-model="model"
   class="pa-4"
   show-arrows
   cycle
   center-active
 >
   <v-slide-item
   v-for="(item, i) in carouselItems"
     :key="i"
     v-slot="{ active, toggle }"
   >
     <v-card
       class="ma-6"
       :height="active ? '30vh' : '25vh'"
       :width="active ? '30vh' : '25vh'"
       @click="toggle"
     >
     <v-img :src="item.src" :height="active ? '30vh' : '25vh'"
      :width="active ? '30vh' : '25vh'">

     </v-img>
       <v-row
         class="fill-height"
         align="center"
         justify="center"
       >
         <v-scale-transition>


         </v-scale-transition>
       </v-row>
     </v-card>
   </v-slide-item>
 </v-slide-group>

 <v-expand-transition>
   <v-sheet
     v-if="model != null"

     tile
     class="mt-3"

     style="border-style: none;border-width: 5px; border-color:rgba(88, 82, 48, 0.07);background-color:rgba(191, 194, 196, 0.2)"

   >
     <v-row
       class="fill-height pb-3"
       align="left"
       justify="left"
     >

       <div style="font-size:2.3vh;margin-left:7%;margin-right:90%;padding-top:4vh;color:grey">
           {{newsDetail[model].time}}
       </div>

       <div style="font-size:2.7vh;margin-left:7%; color:#292929;" >
           {{newsDetail[model].title}}
       </div>
       </v-row>
       <v-row>
       <div style="font-size:2.3vh;text-align:left;padding-top:3vh;margin-bottom:2vh;margin-right:10%;margin-left:7%;color:#292929;border-top: 1px solid grey;">
            {{newsDetail[model].detail}}
         <a :href="newsDetail[model].link">了解更多>></a>
       </div>
       </v-row>


   </v-sheet>

 </v-expand-transition>

</div>
</template>

<script>
export default {
  name: 'News',
  data(){
    return{
      model: 0,
      carouselItems: [
        {
          src: require('@/assets/phone-04.png')
        },
        {
          src: require('@/assets/mp-1_copy.png')
        },
        {
          src: require('@/assets/phone-01.png')
        },
        {
          src: require('@/assets/phone-02.png')
        },
        {
          src: require('@/assets/phone-03.png')
        },
        {
          src: require('@/assets/phone-04.png')
        },
        {
          src: require('@/assets/mp-1_copy.png')
        },
        {
          src: require('@/assets/phone-01.png')
        },
        {
          src: require('@/assets/phone-02.png')
        },
        {
          src: require('@/assets/phone-03.png')
        },
        {
          src: require('@/assets/phone-04.png')
        },
        {
          src: require('@/assets/mp-1_copy.png')
        },
        {
          src: require('@/assets/phone-01.png')
        },
        {
          src: require('@/assets/phone-02.png')
        },
        {
          src: require('@/assets/phone-03.png')
        },
        {
          src: require('@/assets/phone-04.png')
        },
      ],
      newsDetail: [
        {
          time: '2022/07/01',
          title:  '受张某某委托甘肃玉泽律师事务所汪永海、耿晓慧律师对现场进行调查了解，并出席听证会',
          detail: '近日，榆中县自然资源局认为张某某擅自占用集体土地（均为基本农田）进行项目建设，拟对其作出责令退还集体土地、拆除地上建构筑物并处以486888元罚款的行政处罚决定。张某某家境困难，父亲因脑溢血瘫痪在床，为了生存只是在自家林地里搭建了几间简易棚房，供市民休闲采摘，认为对其作出如此严重的行政处罚不当，依法向榆中县自然资源局申请听证。',
          link: 'https://mp.weixin.qq.com/s/0FW6tE8X8bqaFRY3Xa5rMg'
        },
        {
          time: '2022/06/02',
          title:  '婚姻家事纠纷案例研讨',
          detail: '婚姻家事纠纷案例研讨第一期之“特殊同居关系”解除并返还彩礼系列问题简述。',
          link: 'https://mp.weixin.qq.com/s/SFf8IkJPw-cq3Ea_GnwwVg'
        },
        {
          time: '2022/05/19',
          title:  '土地出让金专辑（第三期）',
          detail: '迟延缴纳土地出让金，自然资源部门按每日千分之一收取违约金明显不当，法院应当调减。',
          link: 'https://mp.weixin.qq.com/s/VW-giTW4TeRT8FEZydsFkg'
        },
        {
          time: '2022/05/11',
          title:  '土地出让金专辑（第二期）',
          detail: '土地评估期日确定不合法，也许会多交巨额土地出让金。',
          link: 'https://mp.weixin.qq.com/s/3mRLRT6hc8i2ddG2avKETg'
        },
        {
          time: ' 2022/05/10',
          title:  '玉泽律师房地产团队协助顾问单位处理施工复杂疑难事务',
          detail: '近期，玉泽律师房地产团队负责人汪永海与承办律师一同参加兰州市救助管理站（以下简称：救助站）马滩住宅综合楼项目竣工验收前的协调会议。',
          link: 'https://mp.weixin.qq.com/s/j9U07ANBipAkOZ9ACZ1OHQ'
        },
        {
          time: '2022/05/07',
          title:  '土地出让金专辑（第一期）',
          detail: '产权登记历史遗留问题办理中建设单位应该缴纳土地出让金，而不是土地收益金。',
          link: 'https://mp.weixin.qq.com/s/oYLOvYWJTibCUu1ETOl_WA'
        },
        {
          time: '2022/04/30',
          title:  '搬迁公告',
          detail: '玉泽律师为了更好的发展，同时，也为了给您提供更优质、高效和便捷的法律服务，即日起搬迁至兰州市城关区通渭路1号兰州房地产大厦15层办公办公。给您带来不便，敬请谅解！',
          link: 'https://mp.weixin.qq.com/s/1_JVEYV411VJ1zqlZHZnjw'
        },
        {
          time: '2022/03/17',
          title:  '判决书新鲜出炉！法院判决撤销城关区城管局做出的《强制拆除决定书》',
          detail: '法院判决撤销城关区城管局做出的《强制拆除决定书》，涉案房屋地上商业建筑面积六万多平米，估值十亿余元资产转危为安。',
          link: 'https://mp.weixin.qq.com/s/VEFssrTx6Sp-MpJjnVkimQ'
        },
        {
          time: '2022/02/24',
          title:  '政府对未及时登记的经适房要求缴纳土地收益金违反行政合理原则',
          detail: '对于未及时登记的经济适用房，政府要求补缴土地收益金是违反行政合理规则的。',
          link: 'https://mp.weixin.qq.com/s/oqKZKl-IJUQtaIfX9pgmRg'
        },
        {
          time: '2021/09/23',
          title:  '关于住建部《加强超高层建筑规划建设管理通知（征求意见稿》的意见和建议',
          detail: '9月10日，住房和城市建设部就《关于加强超高层建筑规划建设管理的通知（征求意见稿）》公开征求意见。9月23日，甘肃玉泽律师事务所主任汪永海律师就该通知提出了自己的建议。',
          link: 'https://mp.weixin.qq.com/s/KqSYxWYAyfJeWuZQF7LDFQ'
        },
        {
          time: '2021/04/29',
          title:  '玉泽律师事务所参加安宁区刘家堡街道棚户区（城中村）改造安置小区二期项目方案调整听证会',
          detail: '根据行政许可法规定，行政许可直接涉及申请人与他人之间重大利益关系的，行政机关在作出行政许可决定前，应告知申请人、利害关系人享有要求听证的权利，申请人、利害关系人有权提出听证申请。据此规定，经相关利害关系人申请，兰州市安宁区刘家堡街道棚户区（城中村）改造安置小区二期项目方案调整听证会于2021年4月26日在兰州市自然资源局会议室召开。',
          link: 'https://mp.weixin.qq.com/s/ciz0L67887g9IsrCytDG_g'
        },
        {
          time: '2020/11/28',
          title:  '汪永海主任对艾黎投资集团相关员工进行房地产开发全流程培训',
          detail: '2020年11月27日，甘肃玉泽律师事务所汪永海主任前往甘肃艾黎投资集团有限公司，针对房地产项目整体开发流程对相关员工进行了培训。',
          link: 'https://mp.weixin.qq.com/s/YEHLMueaClWJXgYuMfGs_g'
        },
        {
          time: '2020/10/9',
          title:  '汪永海主任参加安宁城发集团项目竣工验收前自检自验现场会',
          detail: '2020年10月7日，甘肃玉泽律师事务所汪永海主任一行接受邀请，前往安宁区安宁堡街道棚户区改造安置项目施工工地，参加安宁城市发展集团有限公司组织的项目竣工验收前的自检自验现场会。',
          link: 'https://mp.weixin.qq.com/s/rs5NZN2RLyw4ytTl4JaGhg'
        },
        {
          time: '2020/9/28',
          title:  '玉泽搬新家啦！',
          detail: '2020年9月11日，甘肃玉泽律师事务所正式由之前西关什字静安小区内，搬迁至位于雁滩高科·高新大厦B座10楼。',
          link: 'https://mp.weixin.qq.com/s/CxCY8mohYrxj59ITLeqnXg'
        },
        {
          time: '2020/10/9',
          title:  '玉泽所主任汪永海应邀赴安宁城市发展集团进行法律讲座',
          detail: '2020年9月25日，甘肃玉泽律师事务所主任汪永海应邀来到兰州市安宁城市发展集团有限公司，对公司的业务骨干进行了一场精彩的法律讲座。',
          link: 'https://mp.weixin.qq.com/s/hHnsA-BpEYNK_l7F_7rz5w'
        },
        {
          time: '2020/1/13',
          title:  '玉泽所参与七里河区八里镇“法律服务进宗教活动场所”宣讲活动',
          detail: '2019年12月31日上午，甘肃玉泽律师事务所汪永海主任来到八里镇五里埔村土主庙，进行了两场关于宪法基础知识和宗教实务管理知识的宣传讲座。',
          link: 'https://mp.weixin.qq.com/s/49XYXWYk3dsnEpMehK63Qw'
        },

      ]

    }
  },
  mounted: function () {
        this.$nextTick(function () {
            window.setInterval(() => {
              if (this.model == null){
                this.model = null
              }
            else if (this.model == this.carouselItems.length - 1) {
                 this.model = 0
               }
            else{this.model = this.model + 1}
          },8000);
        })
    }
}
</script>
